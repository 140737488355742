<template>
  <div id="FaceRecognition">
    <van-form ref="form" class="form">
      <van-uploader v-model="fileList" multiple :max-count="1" />
      <div style="padding:10px 0">点击上方图标选择照片进行人脸识别</div>
    </van-form>
    <van-button type="primary" block style="margin-top:10px" :loading="loading" @click="faceRecognition">识别</van-button>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { faceRecognitionApi } from '@/api/faceRecognition/face'
import { getStuinfo } from '@/api/modules/xj/stuInfo'

export default {
  name: "FaceRecognition",
  data() {
    return {
      fileList: [],
      loading: false,
    }
  },
  created() {

  },
  methods: {
    toStuDeatil(stuNo) {
      this.$router.push({
        path: "/js/stu/detail",
        query: { stu: btoa(stuNo) },
      });
    },
    compressImage(file, maxWidth, maxHeight, quality, callback) {
      var reader = new FileReader();
      reader.onload = function (event) {
        var image = new Image();
        image.onload = function () {
          var canvas = document.createElement('canvas');
          var context = canvas.getContext('2d');

          // 计算压缩后的图片尺寸
          var width = image.width;
          var height = image.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          // 设置canvas尺寸，并绘制压缩后的图片
          canvas.width = width;
          canvas.height = height;
          context.drawImage(image, 0, 0, width, height);

          // 将canvas转换为压缩后的图片文件
          canvas.toBlob(function (blob) {
            callback(blob);
          }, 'image/jpeg', quality);
        };
        image.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    async faceRecognition() {
      if (this.fileList.length != 1) {
        Toast.fail({
          message: '请上传需要识别的人像图片',
          position: 'top'
        });
        return;
      }

      let file = this.fileList[0]
      const data = new FormData();

      data.append("size", "1");
      data.append("isCheck", "false");

      // 对上传成功的图片进行压缩
      var compressedMaxWidth = 800; // 最大宽度
      var compressedMaxHeight = 600; // 最大高度
      var compressedQuality = 0.8; // 压缩质量（0到1之间）
      let _this = this
      _this.compressImage(file.file, compressedMaxWidth, compressedMaxHeight, compressedQuality, function (compressedBlob) {
        // 压缩后的图片文件 compressedBlob
        data.append("multipartFile", compressedBlob);
        _this.loading = true;
        faceRecognitionApi(data).then((res) => {
          if (res && res.code === 200) {
            let data = res.resultDataList;
            _this.toStuDeatil(data[0].perCode);
          } else if (res && res.code === 300) {
            Toast({
              message: res.message,
              position: 'top',
              duration: 5000
            });
          } else {
            Toast({
              message: res.message,
              position: 'top'
            });
          }
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
          Toast({
            message: '人脸识别调用失败请重试！',
            position: 'top'
          });
        });
      });

    },
  }
}
</script>

<style lang="scss" scoped>
#FaceRecognition {
  padding: 15px;
  .form {
    text-align: center;
  }
  ::v-deep .van-uploader__upload-icon {
    font-size: 51px !important;
  }
  ::v-deep .van-uploader__upload {
    width: 100%;
    min-height: 300px;
  }
  ::v-deep .van-uploader__preview-image {
    min-width: 300px;
    width: 100% !important;
    min-height: 300px;
  }
  ::v-deep .van-uploader__wrapper {
    justify-content: center;
  }
  ::v-deep .van-uploader {
    width: 100%;
  }
  ::v-deep .van-uploader__preview-delete {
    width: 35px;
    height: 35px;
  }
  ::v-deep .van-uploader__preview-delete-icon {
    font-size: 40px;
  }
}
</style>